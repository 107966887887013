import React from 'react';
import { AuthProvider } from './context/AuthContext';
import LoginForm from './components/LoginForm';
import UserInfo from './components/UserInfo';

const App = () => {
  return (
    <AuthProvider>
      <div>
        <LoginForm />
        <UserInfo />
      </div>
    </AuthProvider>
  );
};

export default App;
