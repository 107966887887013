import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getPersonalInfoAndHours } from '../api/user';
import './UserInfo.css'; // Import the CSS file

const UserInfo = () => {
  const { authState } = useContext(AuthContext);
  const { token } = authState;

  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      getPersonalInfoAndHours(token)
        .then(setUserInfo)
        .finally(() => setLoading(false));
    }
  }, [token]);

  if (loading) return <div></div>;
  if (!userInfo) return <div>No user information available</div>;

  return (
    <div className="user-info-container">
      <h1>User Information</h1>
      <p>Login: {userInfo.login}</p>
      <p>Hours: {userInfo.hours}</p>
      <p>Life: {userInfo.life}</p>
    </div>
  );
};

export default UserInfo;
